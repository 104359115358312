import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql, Link } from "gatsby"

import Layout from "../layouts/Layout"
import SiteMetadata from "../components/SiteMetadata"
import Testimonials from "../components/Testimonials"
import { filterByLocale } from "../helpers"

const SocialMediaLandingPage = props => {
  console.log(props)
  const landingPageImages = props.data.socialMediaImages.edges

  const currentLocale = "pt-BR"
  const testimonials = filterByLocale(props.data.testimonial, currentLocale)

  return (
    <Layout>
      <SiteMetadata
        title="Pri Bellafronte - Designer de marcas e logos | Social Media"
        description={
          "Esta página apresenta meu trabalho como Designer " +
          "Gráfico para redes sociais, com artes e meu processo criativo"
        }
        image={props.data.socialCoverImage.publicURL}
      />
      <div>
        <div className="container py-12 lg:pb-16">
          <div className="mt-8 flex flex-wrap">
            <div className="w-full md:w-1/2 px-8 ">
              <p className="text-4xl font-bold mb-4">
                Design para redes sociais profissional
              </p>
              <p className="text-xl">
                Divulgue seu trabalho nas redes sociais de forma profissional.
                Use posts com alto engajamento do seu público, apareça mais, se
                posicione como um profissional de referência e aumente suas
                vendas.
              </p>
              <div className="mx-auto px-12">
                <Link
                  className={
                    "mt-8 md:mt-24 mb-8 md:mb-2 text-center w-full inline-block text-lg sm:text-lg font-medium " +
                    "border-2 p-3 border-transparent font-color-off-white hover:bg-gray-700 " +
                    "hover:border-gray-800 hover:border-2 transition duration-150 ease-in-out " +
                    "site-color-black font-bold rounded-lg"
                  }
                  activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
                  to="/solicitar-orcamento"
                  target="_blank"
                  rel="noreferrer"
                >
                  Solicite agora seu orçamento
                </Link>
              </div>
            </div>
            <div className="w-full md:w-1/2 flex flex-wrap pb-8 md:pb-0">
              {landingPageImages.map(imageData => {
                return (
                  <div className="w-full md:w-1/2 p-2">
                    <GatsbyImage
                      image={imageData.node.childImageSharp.gatsbyImageData}
                      alt={imageData.node.childImageSharp.name}
                      className=""
                    />
                  </div>
                )
              })}
            </div>
          </div>
          <div className="mt-8 md:mt-32 flex flex-wrap text-center">
            <h2 className="mx-auto text-3xl text-center font-bold">
              Pacotes e valores para redes sociais
            </h2>
          </div>
          <div className="mt-6 flex flex-wrap">
            <div className="w-full sm:w-1/3 mb-8 sm:mb-0 px-2">
              <div className="border-2 border-black rounded shadow-xl">
                <div className="bg-black text-white text-center py-4">
                  <h3 className="font-semibold mx-auto text-2xl text-center background text-white">
                    Básico
                  </h3>
                  <p>R$ 850</p>
                  <p>por mês</p>
                </div>
                <div className="bg-gray-200">
                  <p className="text-xl text-center my-1">
                    2 postagens por semana
                  </p>
                  <p className="text-xl text-center my-1">
                    Abrange Facebook e Instagram
                  </p>
                  <p className="text-xl text-center my-1">
                    Apresentação de resultados mensal
                  </p>
                  <p className="text-xl text-center my-1">Suporte online</p>
                  <p className="text-xl text-center my-1">
                    2 stories por semana
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/3 mb-8 sm:mb-0 px-2">
              <div className="border-2 border-black rounded shadow-xl">
                <div className="bg-black text-white text-center py-4">
                  <h3 className="font-semibold mx-auto text-2xl text-center background text-white">
                    Avançado
                  </h3>
                  <p>R$ 1.150</p>
                  <p>por mês</p>
                </div>
                <div className="bg-gray-200">
                  <p className="text-xl text-center my-1">
                    3 postagens por semana
                  </p>
                  <p className="text-xl text-center my-1">
                    Abrange Facebook e Instagram
                  </p>
                  <p className="text-xl text-center my-1">
                    Apresentação de resultados mensal
                  </p>
                  <p className="text-xl text-center my-1">Suporte online</p>
                  <p className="text-xl text-center my-1">
                    3 stories por semana
                  </p>
                </div>
              </div>
            </div>
            <div className="w-full sm:w-1/3 mb-8 sm:mb-0 px-2">
              <div className="border-2 border-black rounded shadow-xl">
                <div className="bg-black text-white text-center py-4">
                  <h3 className="font-semibold mx-auto text-2xl text-center background text-white">
                    Plus
                  </h3>
                  <p>R$ 2.200</p>
                  <p>por mês</p>
                </div>
                <div className="bg-gray-200">
                  <p className="text-xl text-center my-1">
                    5 postagens por semana
                  </p>
                  <p className="text-xl text-center my-1">
                    Abrange Facebook e Instagram
                  </p>
                  <p className="text-xl text-center my-1">
                    Criação de texto para descrição
                  </p>
                  <p className="text-xl text-center my-1">
                    Apresentação de resultados mensal
                  </p>
                  <p className="text-xl text-center my-1">Suporte online</p>
                  <p className="text-xl text-center my-1">
                    5 stories por semana
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-8 md:mt-32 flex flex-wrap text-center">
            <h2 className="mx-auto text-3xl text-center font-bold">
              Como vai funcionar?
            </h2>
          </div>
          <div className="mt-6">
            <p className="text-2xl">1. Orçamento</p>
            <p className="text-lg mt-4">
              Primeiro, você me manda uma mensagem me contando o que você
              precisa. Quantas artes por mês ou por semana quer postar, qual o
              seu objetivo. Eu responderei em no máximo 24 horas com os
              detalhes.
            </p>
          </div>
          <div className="mt-6">
            <p className="text-2xl">2. Planejamento</p>
            <p className="text-lg mt-4">
              Depois de fechados os trâmites para contratação, coleto mais
              informações e assim definimos um calendários de postagens, com os
              temas e totalmente aderente às informações enviadas.
            </p>
          </div>
          <div className="mt-6">
            <p className="text-2xl">3. Postagem</p>
            <p className="text-lg mt-4">
              No caso de contratação apenas da criação das postagens, envio as
              artes com dias de sobra para que a postagem seja agendada sem
              pressa. Caso tenha sido feita a contratação da gestão das redes
              sociais, então eu mesmo posto, e o cliente não precisa se
              preocupar com nada, apenas acompanhar.
            </p>
          </div>
          <div className="mt-6">
            <p className="text-2xl">4. Acompanhamento</p>
            <p className="text-lg mt-4">
              Aqui, acompanharemos os resultados das postagens, mas sempre
              entendendo que este é um trabalho de médio / longo prazo, mas cuja
              consistência na identidade visual fará toda a diferença.
            </p>
          </div>
          <div className="flex flex-wrap">
            <Link
              className={
                "mx-auto mt-16 text-center w-10/12 md:w-1/3 inline-block text-lg sm:text-lg font-medium " +
                "border-2 p-3 border-transparent font-color-off-white hover:bg-gray-700 " +
                "hover:border-gray-800 hover:border-2 transition duration-150 ease-in-out " +
                "site-color-black font-bold rounded-lg"
              }
              activeClassName="border-blue-600 text-gray-900 hover:border-blue-600"
              to="/solicitar-orcamento"
              target="_blank"
              rel="noreferrer"
            >
              Vamos trabalhar juntos?
            </Link>
          </div>
          <div>
            <div className="mt-16 flex flex-wrap text-center">
              <h2 className="mx-auto text-3xl text-center font-bold">
                Ainda tem dúvidas?
              </h2>
            </div>
            <div className="mt-6">
              <p className="text-lg mt-4">
                Se ainda tem dúvidas sobre o trabalho em si, pode entrar em
                contato comigo, tanto por email quanto por Whatsapp. Mas se tem
                algum receio sobre a qualidade do trabalho, saiba que uso minha
                técnica e tenho uma dedicação incansável pela satisfação dos
                meus clientes. Abaixo, alguns depoimentos de gente que já
                trabalhou comigo e saiu muito feliz.
              </p>
            </div>
          </div>
        </div>
        <div className="py-8 lg:py-12 site-color-black font-color-white mt-8">
          <Testimonials testimonials={testimonials} />
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    socialMediaImages: allFile(
      filter: { absolutePath: { regex: "/landing-page-redes-sociais/" } }
    ) {
      edges {
        node {
          name
          childImageSharp {
            gatsbyImageData
          }
          relativePath
        }
      }
    }
    testimonial: allContentfulTestimonial {
      nodes {
        company
        name
        node_locale
        title
        testimonial {
          testimonial
        }
        photo {
          gatsbyImageData(layout: CONSTRAINED)
        }
      }
    }
    socialCoverImage: file(
      relativePath: { eq: "bellafronte-studio-social-cover.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          width: 400
          height: 400
          quality: 90
          layout: CONSTRAINED
        )
      }
      publicURL
    }
  }
`

export default SocialMediaLandingPage
